import React, { useState, useEffect } from "react"

const timeoutLength = 8000

const defaultValues = {
  showNotification: false,
  notification: {
    type: "",
    messages: [],
  },
  sendNotification: () => {},
}
export const NotificationContext = React.createContext(defaultValues)

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({ type: "", messages: [] })
  const [showNotification, setShowNotification] = useState(false)

  const sendNotification = (notification) => {
    setNotification(notification)
  }

  useEffect(() => {
    let isSubscribed = true
    let timeout
    if (notification?.messages && notification.messages.length > 0) {
      setShowNotification(true)
      timeout = setTimeout(() => {
        if (isSubscribed) {
          setShowNotification(false)
        }
      }, timeoutLength)
    }

    return () => {
      isSubscribed = false
      clearTimeout(timeout)
    }
  }, [notification])

  return (
    <NotificationContext.Provider
      value={{
        ...defaultValues,
        notification,
        showNotification,
        sendNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

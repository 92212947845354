import { createClient } from "urql"

const client = createClient({
  url: `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/2022-01/graphql.json`,
  fetchOptions: () => {
    return {
      headers: {
        "X-Shopify-Storefront-Access-Token":
          process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
      },
    }
  },
})

export const isBrowser = () => typeof window !== "undefined"

const setUser = (user) =>
  window.localStorage.setItem("idcUser", JSON.stringify(user))

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("idcUser")
    ? JSON.parse(window.localStorage.getItem("idcUser"))
    : {}

export const handleLogin = async ({ email, password }) => {
  if (email !== "" && password !== "") {
    // call login mutation
    const LoginQuery = `
      mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
        customerAccessTokenCreate(input: $input) {
            customerUserErrors {
              code
              field
              message
            }
            customerAccessToken {
              accessToken
              expiresAt
            }
          }
        }
      `

    const CustomerIdQuery = `
    query ($customerAccessToken: String!) {
      customer (customerAccessToken: $customerAccessToken) {
        id
      }    
    }
    `

    let customerData = {}
    let response = await client
      .mutation(LoginQuery, {
        input: {
          email: email,
          password: password,
        },
      })
      .toPromise()
      .then((result) => {
        const { data, error } = result
        customerData = data
        if (data?.customerAccessTokenCreate?.customerAccessToken)
          setUser(data.customerAccessTokenCreate.customerAccessToken)
        if (data) return data
      })

    await client
      .query(CustomerIdQuery, {
        customerAccessToken:
          customerData?.customerAccessTokenCreate?.customerAccessToken
            ?.accessToken,
      })
      .toPromise()
      .then((result) => {
        const { data, error } = result
        if (data?.customer?.id)
          setUser({
            ...customerData.customerAccessTokenCreate.customerAccessToken,
            ...data?.customer,
            email,
          })
        // Send event to google tag manager
        window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "login",
          method: "IDC-Login",
        })
      })

    if (response) return response
  }

  return false
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.accessToken && new Date(user.expiresAt) > new Date()
}

export const logout = (callback) => {
  setUser({})
  callback()
}

export const getMultipassUrl = async (email, accessToken, checkoutUrl) => {
  const result = await fetch("/api/auth/multipass", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      checkoutUrl,
      email,
      accessToken,
    }),
  }).then((res) => res.json())

  return result
}

export const updateProfile = async (email, firstName, lastName, input) => {
  const user = getUser()

  const result = await fetch("/api/update-profile", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: user.id,
      email,
      firstName,
      lastName,
      input,
    }),
  }).then((res) => res.json())

  return result
}

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-strapi-article-types-handle-index-tsx": () => import("./../../../src/pages/blog/{strapiArticleTypes.handle}/index.tsx" /* webpackChunkName: "component---src-pages-blog-strapi-article-types-handle-index-tsx" */),
  "component---src-pages-blog-strapi-articles-handle-index-tsx": () => import("./../../../src/pages/blog/{strapiArticles.handle}/index.tsx" /* webpackChunkName: "component---src-pages-blog-strapi-articles-handle-index-tsx" */),
  "component---src-pages-blog-video-index-tsx": () => import("./../../../src/pages/blog/video/index.tsx" /* webpackChunkName: "component---src-pages-blog-video-index-tsx" */),
  "component---src-pages-brands-jsx": () => import("./../../../src/pages/brands.jsx" /* webpackChunkName: "component---src-pages-brands-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-coffee-subscription-select-choices-tsx": () => import("./../../../src/pages/coffee-subscription/select-choices.tsx" /* webpackChunkName: "component---src-pages-coffee-subscription-select-choices-tsx" */),
  "component---src-pages-combos-jsx": () => import("./../../../src/pages/combos.jsx" /* webpackChunkName: "component---src-pages-combos-jsx" */),
  "component---src-pages-returns-jsx": () => import("./../../../src/pages/returns.jsx" /* webpackChunkName: "component---src-pages-returns-jsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-templates-cms-content-js": () => import("./../../../src/templates/cms-content.js" /* webpackChunkName: "component---src-templates-cms-content-js" */),
  "component---src-templates-cms-js": () => import("./../../../src/templates/cms.js" /* webpackChunkName: "component---src-templates-cms-js" */),
  "component---src-templates-collections-listing-tsx": () => import("./../../../src/templates/collections/listing.tsx" /* webpackChunkName: "component---src-templates-collections-listing-tsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}


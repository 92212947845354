import * as React from "react"
import Client from "shopify-buy"
import { useMutation, useQuery } from "urql"
import { getUser } from "services/auth"
import bonusPackagesData from "./../data/bonusPackages.json"
import { addToCartMultipleItems } from "../utils/addToCartMultipleItems"
import { logout } from "../services/auth"
import { navigate } from "gatsby"

if (typeof window !== "undefined") {
  const getGclid = () => {
    const url = new URL(window.location.href)
    const gclid = url.searchParams.get("gclid")
    return gclid
  }

  if (getGclid()) {
    localStorage.removeItem("gclid")

    localStorage.setItem("gclid", getGclid())
  }

  //get the document.referrer value and store it in localStorage
  const getReferrer = () => {
    const referrer = document.referrer
    return referrer
  }

  //add discountCode to localStorage if there is a query parameter with discount=
  const getDiscountCode = () => {
    const url = new URL(window.location.href)
    const discountCode = url.searchParams.get("discount")
    return discountCode
  }

  if (getDiscountCode()) {
    localStorage.removeItem("discountCode")
    localStorage.setItem("couponCode", getDiscountCode(), 1)
    localStorage.setItem("discountCode", getDiscountCode())
  }

  if (getReferrer()) {
    localStorage.setItem("referrer", getReferrer())
  }
}

const CustomerAssociateQuery = `
mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
  checkoutCustomerAssociateV2(
    checkoutId: $checkoutId
    customerAccessToken: $customerAccessToken
  ) {
    checkout {
      id
    }
    checkoutUserErrors {
      code
      field
      message
    }
    customer {
      id
    }
  }
}
`

const CheckoutShippingAddressQuery = `
mutation checkoutShippingAddressUpdateV2($shippingAddress: MailingAddressInput!, $checkoutId: ID!) {
  checkoutShippingAddressUpdateV2(
    shippingAddress: $shippingAddress
    checkoutId: $checkoutId
  ) {
    checkout {
      id
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}
`

const ProfileQuery = `
  query ($customerAccessToken: String!) {
    customer (customerAccessToken: $customerAccessToken) {
        defaultAddress {
            lastName
            firstName
            address1
            province
            country
            zip
            city
        }
      }
    }
  `

const client = Client.buildClient({
  domain: process.env.GATSBY_SHOPIFY_STORE_URL,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
})

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  addMultipleVariantsToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
    discountCode: "",
  },
  couponCodeString: "",
  megaMenuOpen: false,
  megaMenuHeight: 0,
  currentDropDown: 999,
  megaMenuSetHeight: () => {},
  openMegaMenu: () => {},
  closeMegaMenu: () => {},
  dropDownTrigger: () => {},
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({ children }) => {
  const user = getUser()
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [loading, setLoading] = React.useState(false)
  const [megaMenuOpen, setMenuState] = React.useState(false)
  const [megaMenuHeight, setMenuHeight] = React.useState(0)
  const [currentDropDown, setDropDown] = React.useState(999)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)
  const [, customerAssociate] = useMutation(CustomerAssociateQuery)
  const [, checkoutShippingAddress] = useMutation(CheckoutShippingAddressQuery)
  const [profileQueryResult] = useQuery({
    query: ProfileQuery,
    variables: {
      customerAccessToken: user.accessToken,
    },
  })

  // The code below is needed as the forced (production checkout bug) switch from Gatsby to Netlify broke existing session saved in local storage.
  // The intend of this code is to invalidate all sessions that haven't been reinitialized to make sure they work (account page not breaking),
  // while at the same time not reinitalizing sessions that are new or have been reinitalized.
  //
  // Due to the expiresAt time set for sessions, this code can be removed after 2 months.
  // Today is the 09.05.23

  const isBrowser = () => typeof window !== "undefined"

  const getSessionRestored = () => {
    return isBrowser() && window.localStorage.getItem("sessionRestored")
      ? JSON.parse(window.localStorage.getItem("sessionRestored"))
      : false
  }

  const setSessionRestored = (state) => {
    isBrowser() && window.localStorage.setItem("sessionRestored", state)
  }
  const reinitializeSession = () => {
    logout(() => {
      clearCheckout()
      initializeCheckout()
      navigate("/account/")
    })
    setSessionRestored(true)
  }
  const reinitSessionIfBroken = () => {
    const localStorageIdcUser = getUser()
    const localStorageSessionRestored = getSessionRestored()
    console.log(
      localStorageSessionRestored,
      localStorageIdcUser,
      Object.keys(localStorageIdcUser).length === 0
    )
    if (
      localStorageSessionRestored ||
      Object.keys(localStorageIdcUser).length === 0 ||
      !localStorageIdcUser
    ) {
      setSessionRestored(true)
      return
    } else {
      reinitializeSession()
    }
  }

  //set loading until ready
  React.useEffect(() => {
    setLoading(true)
    reinitSessionIfBroken() // See comment above
  }, [])

  // const { profileData, fetching, error } = profileQueryResult;

  //if there is shopify_checkout_id in localStorage, save it as a cookie of the same name
  React.useEffect(() => {
    if (isBrowser) {
      const existingCheckoutId = localStorage.getItem(localStorageKey)
      if (existingCheckoutId) {
        document.cookie = `${localStorageKey}=${existingCheckoutId
          .replace("gid://shopify/Checkout/", "")
          .replace("?key=", "")}`
      }
    }
  }, [])

  const updateShippingAddress = (checkoutObject = checkout) => {
    const user = getUser()
    customerAssociate({
      customerAccessToken: user.accessToken,
      checkoutId: checkoutObject.id,
    })

    const { data: profileData } = profileQueryResult
    if (profileData && profileData.customer.defaultAddress) {
      const customerAddress = { ...profileData.customer.defaultAddress }
      delete customerAddress["__typename"]

      checkoutShippingAddress({
        shippingAddress: customerAddress,
        checkoutId: checkoutObject.id,
      })
    }
  }

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
    updateShippingAddress(checkout)
  }

  // clearing previously tracked product types
  const setDefaultProductTypes = () => {
    if (isBrowser) {
      const newObject = JSON.stringify({ value: {} })
      localStorage.setItem("productTypes", newObject)
    }
  }

  const initializeCheckout = async () => {
    const existingCheckoutID = isBrowser
      ? localStorage.getItem(localStorageKey)
      : null

    if (existingCheckoutID && existingCheckoutID !== `null`) {
      try {
        const existingCheckout = await client.checkout.fetch(existingCheckoutID)
        if (!existingCheckout.completedAt) {
          setCheckoutItem(existingCheckout)
          return
        }
      } catch (e) {
        localStorage.setItem(localStorageKey, null)
        setDefaultProductTypes()
      }
    }

    const newCheckout = await client.checkout.create()
    setCheckoutItem(newCheckout)
    setDefaultProductTypes()
  }

  const clearCheckout = () => {
    localStorage.setItem(localStorageKey, null)
  }

  React.useEffect(() => {
    initializeCheckout()
  }, [])

  const sendGTMAddToCart = (event, checkout, addedVariantId) => {
    if (typeof window !== "undefined" && window.dataLayer) {
      const decodedAddedVariantId = addedVariantId

      const lineItem = checkout.lineItems.find(
        (item) => item.variant.id === decodedAddedVariantId
      )

      const selectedVariant = lineItem.variant
      const productGidSplitArray = selectedVariant.product.id.split("/")
      const varantGidSplitArray = selectedVariant.id.split("/")

      const parentProductId =
        productGidSplitArray[[productGidSplitArray.length - 1]]
      const variantId = varantGidSplitArray[[varantGidSplitArray.length - 1]]
      const merchantID = `shopify_CA_${parentProductId}_${variantId}`

      window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
      window.dataLayer.push({
        event,
        currency: checkout.currencyCode,
        // assuming that it only ever is one item
        value: parseFloat(selectedVariant.price.amount),
        ecommerce: {
          items: [
            {
              item_name: lineItem.title,
              item_variant: selectedVariant.title,
              price: parseFloat(selectedVariant.price.amount),
              item_id: merchantID,
              id: merchantID,
              quantity: 1,
            },
          ],
        },
      })
    }
  }

  const extractProductIdFromGid = (gid) => {
    const splitArray = gid.split("/")
    return splitArray[[splitArray.length - 1]]
  }

  const sendKlaviyoAddToCart = (checkout, addedVariantId) => {
    if (typeof window === "undefined" || !window?.klaviyo) return null
    const klaviyo = window.klaviyo
    const decodedAddedVariantId = addedVariantId
    const lineItem = checkout.lineItems.find(
      (item) => item.variant.id === decodedAddedVariantId
    )
    const selectedVariant = lineItem.variant
    const item = {
      $value: parseFloat(selectedVariant?.price?.amount),
      AddedItemProductName: lineItem.title,
      AddedItemProductID: extractProductIdFromGid(selectedVariant?.product?.id),
      AddedItemVariantID: extractProductIdFromGid(selectedVariant?.id),
      AddedItemSKU: selectedVariant.sku,
      AddedItemPrice: parseFloat(selectedVariant?.price?.amount),
      AddedItemImageURL: selectedVariant?.image?.src,
      AddedItemQuantity: lineItem.quantity,
      CheckoutURL: checkout.webUrl,
      Items: checkout.lineItems.map((item) => {
        return {
          ProductID: extractProductIdFromGid(item.variant.product.id),
          SKU: item.variant.sku,
          ProductName: item.title,
          Quantity: item.quantity,
          ItemPrice: parseFloat(item.variant.price.amount),
          RowTotal: parseFloat(item.variant.price.amount) * item.quantity,
          ImageURL: item.variant.image.src,
        }
      }),
    }
    klaviyo.push(["track", "Added to Cart", item])
  }

  const addVariantToCart = async (
    variantId,
    quantity,
    customAttributes = [],
    productType = "",
    setIsCartModalVisible
  ) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes,
      },
    ]

    // Adding a product type so we can rebuild our urls
    if (isBrowser && productType) {
      const newTypes = JSON.parse(localStorage.getItem("productTypes"))
      newTypes.value[variantId] = productType
      const stringifiedTypes = JSON.stringify(newTypes)
      localStorage.setItem("productTypes", stringifiedTypes)
    }

    let couponCode = localStorage.getItem("couponCode")
    if (couponCode) {
      localStorage.removeItem("couponCode")
      checkout.discountCode = couponCode
    }

    localStorage.setItem("discountCode", checkout.discountCode)
    localStorage.removeItem("couponCode")

    if (checkout.discountCode) {
      return (
        client.checkout
          .addLineItems(checkoutID, lineItemsToUpdate)
          .then((res) => {
            setCheckout(res)
            setLoading(false)
            setDidJustAddToCart(true)
            setTimeout(() => setDidJustAddToCart(false), 1000)
          })

          // apply the discount code to the checkout
          // if discountCode is not null

          .then(() => {
            client.checkout
              .addDiscount(checkoutID, checkout.discountCode)
              .then((updatedCheckout) => {
                const addedVariantId = variantId
                setCheckout(updatedCheckout)
                setLoading(false)
                setDidJustAddToCart(true)
                setTimeout(() => setDidJustAddToCart(false), 1000)
                sendGTMAddToCart("add_to_cart", updatedCheckout, addedVariantId)
                sendKlaviyoAddToCart(updatedCheckout, addedVariantId)
                setIsCartModalVisible(true)
              })
          })
      )
    } else {
      return client.checkout
        .addLineItems(checkoutID, lineItemsToUpdate)
        .then((updatedCheckout) => {
          const addedVariantId = variantId
          setCheckout(updatedCheckout)
          setLoading(false)
          setDidJustAddToCart(true)
          setTimeout(() => setDidJustAddToCart(false), 1000)
          sendGTMAddToCart("add_to_cart", updatedCheckout, addedVariantId)
          sendKlaviyoAddToCart(updatedCheckout, addedVariantId)
          setIsCartModalVisible(true)
        })
    }
  }

  // now we will similarly create an addMultipleVariantsToCart function which will
  //allow us to add multiple variants to the cart at once for combo offers or free gifts
  const addMultipleVariantsToCart = async (
    variantIds,
    quantity,
    attributes = [],
    productType = "",
    variants
  ) => {
    setLoading(true)

    const checkoutID = checkout.id

    //first split the variantIds by comma
    const variantIdsArray = variantIds.split(",")

    //then split the quantities by comma
    //then create an array of objects with the variantId and quantity
    const lineItemsToUpdate = variantIdsArray.map((variantId, index) => {
      const variantPrice = Number(
        variants[index].price ?? variants[index]?.variants[0]?.price
      )
      let customAttributes = [...attributes]
      if (freeGiftEnabledVariant(variantPrice, variants[index].tags)) {
        customAttributes.push({
          key: "FreeGiftMessages",
          value: JSON.stringify(getFreeGiftMessages(variantPrice)),
        })
      }
      return {
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes,
      }
    })

    // get the value for couponCode from localStorage and make it discountCode, then remove couponCode from localStorage
    let couponCode = localStorage.getItem("couponCode")
    if (couponCode) {
      localStorage.removeItem("couponCode")
      checkout.discountCode = couponCode
    }

    localStorage.setItem("discountCode", checkout.discountCode)
    localStorage.removeItem("couponCode")
    //get the discount code from the variantIds

    // Adding a product type so we can rebuild our urls
    if (isBrowser && productType) {
      const newTypes = JSON.parse(localStorage.getItem("productTypes"))
      newTypes.value[variantIds] = productType
      const stringifiedTypes = JSON.stringify(newTypes)
      localStorage.setItem("productTypes", stringifiedTypes)
    }

    return (
      client.checkout
        .addLineItems(checkoutID, lineItemsToUpdate)
        .then((res) => {
          addToCartMultipleItems(res)
          setCheckout(res)
          setLoading(false)
          setDidJustAddToCart(true)
          setTimeout(() => setDidJustAddToCart(false), 1000)
        })

        // apply the discount code to the checkout
        .then(() => {
          client.checkout
            .addDiscount(checkoutID, checkout.discountCode)
            .then((res) => {
              setCheckout(res)
              setLoading(false)
              setDidJustAddToCart(true)
              setTimeout(() => setDidJustAddToCart(false), 1000)
            })
        })
    )
  }

  const freeGiftEnabledVariant = (productPrice, tags) => {
    if (typeof tags === "undefined") return false
    return productPrice >= 500 && tags.includes("FG-BONUS-EG")
  }

  const getFreeGiftMessages = (productPrice) => {
    // Move message to an external file
    let messagesArray = [bonusPackagesData[0].message]
    if (productPrice >= 1000) {
      messagesArray.push(bonusPackagesData[1].message)
    }
    if (productPrice >= 2000) {
      messagesArray.push(bonusPackagesData[2].message)
    }

    return messagesArray
  }

  const removeLineItem = (checkoutID, lineItem, setRemoving) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, [lineItem.id])
      .then((res) => {
        setCheckout(res)
        setLoading(false)
        setRemoving(false)
        if (typeof window !== "undefined" && window.dataLayer) {
          const productGidSplitArray = lineItem.variant.product.id.split("/")
          const varantGidSplitArray = lineItem.variant.id.split("/")

          const parentProductId =
            productGidSplitArray[[productGidSplitArray.length - 1]]
          const variantId =
            varantGidSplitArray[[varantGidSplitArray.length - 1]]
          const merchantID = `shopify_CA_${parentProductId}_${variantId}`

          // Send event to google tag manager
          window.dataLayer.push({ items: null }) // Clear the previous ecommerce object.
          window.dataLayer.push({
            event: "remove_from_cart",
            currency: lineItem.variant.price.currencyCode,
            value: parseFloat(lineItem.variant.price.amount),
            items: [
              {
                item_name: lineItem.title,
                item_variant: lineItem.variant.title,
                price: parseFloat(lineItem.variant.price.amount),
                currency: lineItem.variant.price.currencyCode,
                item_id: merchantID,
                id: merchantID,
                quantity: lineItem.quantity,
              },
            ],
          })
        }
      })
  }
  //update the quantity of a line item
  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }
  //update attributes of a line item if applicable
  const updateCartAttributes = async (checkoutID, inputObject) => {
    setLoading(true)
    const response = await client.checkout.updateAttributes(
      checkoutID,
      inputObject
    )

    setCheckout(response)
    setLoading(false)
    return response
  }

  // f

  const addGiftsToCart = async (_, freeGiftRule, freeGiftChoices) => {
    setLoading(true)
    if (freeGiftRule?.code && freeGiftChoices.length > 0) {
      const freeGiftsToAdd = freeGiftChoices.map((item) => ({
        variantId: item,
        quantity: 1,
      }))
      await client.checkout.addLineItems(checkout.id, freeGiftsToAdd)
      const checkoutWithCode = await client.checkout.addDiscount(
        checkout.id,
        freeGiftRule.code
      )

      setCheckout(checkoutWithCode)
    }
    setDidJustAddToCart(true)
    setTimeout(() => setDidJustAddToCart(false), 1000)
    setLoading(false)
  }

  const openMegaMenu = () => {
    setMenuState(true)
  }

  const closeMegaMenu = () => {
    setMenuState(false)
  }

  const megaMenuSetHeight = (e) => {
    setMenuHeight(e)
  }

  const dropDownTrigger = (index) => {
    clearTimeout()
    if (index === 999) {
      setDropDown(999)
      setTimeout(() => {
        megaMenuSetHeight(0)
      }, 350)
    } else {
      setDropDown(index)
    }
  }
  //check if loading is still true, if it is, change the loading state to false after a second
  if (loading) {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        addMultipleVariantsToCart,
        removeLineItem,
        updateLineItem,
        addGiftsToCart,
        updateCartAttributes,
        updateShippingAddress,
        initializeCheckout,
        clearCheckout,
        checkout,
        loading,
        didJustAddToCart,
        megaMenuOpen,
        megaMenuHeight,
        currentDropDown,
        megaMenuSetHeight,
        openMegaMenu,
        closeMegaMenu,
        dropDownTrigger,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

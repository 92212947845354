export const addToCartMultipleItems = (checkout) => {
  const items = checkout.lineItems
  const currency = checkout.currencyCode
  const value = parseInt(checkout.subtotalPrice.amount, 10)
  window?.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window?.dataLayer.push({
    event: "add_to_cart",
    currency,
    value,
    items,
  })
}

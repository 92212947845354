import * as React from "react"
import { createClient, Provider } from "urql"
import { StoreProvider } from "./src/context/store-context"
import { NotificationProvider } from "./src/context/notification-context"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "react-multi-carousel/lib/styles.css"
import "react-lazy-load-image-component/src/effects/blur.css"
import "react-tabs/style/react-tabs.css"
import "react-medium-image-zoom/dist/styles.css"
import "./src/styles/index.css"

const client = createClient({
  url: `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/2021-10/graphql.json`,
  fetchOptions: () => {
    return {
      headers: {
        "X-Shopify-Storefront-Access-Token":
          process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
      },
    }
  },
})

export const wrapRootElement = ({ element }) => (
  <Provider value={client}>
    <StoreProvider>
      <NotificationProvider>{element}</NotificationProvider>
    </StoreProvider>
  </Provider>
)
